import axios from 'axios';
import qs from 'qs';



const url =window.location.origin
// const url ='http://192.168.1.160:80/'
// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = url;

export const axiosUrl =window.location.origin
//注册发送验证码
export const verify = (data) => axios.post('/BuildingCloudSystemWeb/verify',data)
//操作日志
export const erpGetList = (data) => axios.post('/l/erp/getList',data)
//访问日志
export const erpGetLoginList = (data) => axios.post('/l/erp/getLoginList',data)
//清空日志
export const erpdropLogs = (data) => axios.post('/l/erp/dropLogs',data)
//登陆
export const login = (data) => axios.post('/l/user/doLoginByPc',data)
//员工编码登陆
export const doLoginByEmpCode = (data) => axios.post('/l/user/doLoginByEmpCode',data)
//申请token或更新acessToken
export const applyToken = (data) => axios.post('/BuildingCloudGovernmentSystemWeb/applyToken',data)

//获取验证码登录
export const getCaptcha = (data) => axios.post('/l/user/sendCode',data)
//验证码登录
export const doLoginByPhone = (data) => axios.post('/l/user/doLoginByPhone',data)

//登陆后获取用户信息
export const getUserInfo = (data) => axios.post('/l/user/getUserInfo',data)
//退出
export const logout = (data) => axios.post('/l/user/loginOut',data)
//修改密码
export const editPwd = (data) => axios.post('/l/user/editPwd',data)
//登陆后获取权限
export const queryAllPermission = (data) => axios.post('/l/menu/queryAllPermission',data)

//重置密码
export const repwd = (data) => axios.post('/l/user/repwd',data)


//项目总数  视频在线数/总数
export const getTotal = (data) => axios.post('/l/device/getTotal',data)
//获取视频播放页面项目 在线率 视频列表数据
export const deviceGetList = (data) => axios.post('/l/device/getList',data)
//新增设备
export const deviceAdd = (data) => axios.post('/l/device/add',data)
//获取项目列表
export const getProjectList = (data) => axios.post('/l/device/getProjectList',data)
//获取设备管理 视频列表
export const getDeviceList = (data) => axios.post('/l/device/getDeviceList',data)
//阐述谁鄂毕
export const deviceDelete = (data) => axios.post('/l/device/delete',data)
//编辑
export const updateDevice = (data) => axios.post('/l/device/updateDevice',data)
//抓图
export const grabImages = (data) => axios.post('/l/device/capture',data)
//隐藏/恢复
export const hiddenVideo = (data) => axios.post('/l/device/hiddenVideo',data)

export const devicesupplierExportCompany= (data) => axios.get('/BuildingCloudGovernmentRecordWeb/bc/devicesupplier/export?'+qs.stringify(data))
//组织框架
export const getAll = (data) => axios.post('/l/sysOrg/getAll',data)
//组织列表+设备列表
export const orgAndVideo = (data) => axios.post('/l/device/orgAndVideo',data)

//新增组织框架
export const sysOrgSave = (data) => axios.post('/l/sysOrg/save',data)
//修改组织框架
export const sysOrgEdit = (data) => axios.post('/l/sysOrg/edit',data)
//获取组织框架 下的组织
export const sysOrgGetOrgList = (data) => axios.post('/l/sysOrg/getOrgList',data)
//删除组织框架组织
export const sysOrgGetOrDel = (data) => axios.post('/l/sysOrg/del',data)
//id查组织详情
export const sysOrgGetOrGet = (data) => axios.post('/l/sysOrg/get',data)

//获取组织下的用户集合
export const userGetAll = (data) => axios.post('/l/user/getAll',data)
//获取组织下的用户集合  加搜索条件
export const userGetCurry = (data) => axios.post('/l/user/queryUserInfoByOrgId',data)
//新增用户
export const userAdd = (data) => axios.post('/l/user/add',data)
//查看用户详情
export const getUserById = (data) => axios.post('/l/user/getUserById',data)
//修改用户
export const userEdit = (data) => axios.post('/l/user/edit',data)
//删除用户
export const userdel = (data) => axios.post('/l/user/del',data)
//验证电话号码
export const authPhone = (data) => axios.post('/l/user/authPhone',data)

//获取菜单(登陆成功)
export const loginedAumenu = (data) => axios.post('/l/menu/getMenu',data)

//获取菜单(所有)
export const getMenuAll = (data) => axios.post('/l/menu/getMenuAll',data)
//新增菜单
export const saveMenu = (data) => axios.post('/l/menu/save',data)
//获取菜单详情
export const queryMenuById = (data) => axios.post('/l/menu/queryMenuById',data)
//修改菜单
export const menuEdit = (data) => axios.post('/l/menu/edit',data)
//删除菜单
export const menuDel = (data) => axios.post('/l/menu/del',data)

//查询角色
export const roleGetList = (data) => axios.post('/l/role/getList',data)
//新增角色
export const roleSave = (data) => axios.post('/l/role/save',data)
//角色与权限菜单绑定
export const saveRoleAndMenu = (data) => axios.post('/l/middle/saveRoleAndMenu',data)
//根据角色id查询菜单与权限
export const queryMenuByRoleId = (data) => axios.post('/l/middle/queryMenuByRoleId',data)
//删除角色并删除管理菜单和对应用户角色置空
export const roleDel = (data) => axios.post('/l/role/del',data)
